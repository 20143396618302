import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import * as headingStyles from "../css/heading.module.css"
import * as proseStyles from "../css/prose.module.css"
import * as formStyles from "../css/form.module.css"

const Index = () => {
  const data: any = useStaticQuery(graphql`
    query articleQuery {
      allContentfulArticle {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo title="Home" />
      <div className={headingStyles.container}>
        <h1 className={headingStyles.h1}><em>Hi, I'm Hondo.</em> I am a content management expert.</h1>
      </div>
      <div className={proseStyles.prose}>
        <p>I’ve been building sites using open source content management systems for over twenty years. In this time, I have helped guide the design and development of sites used by <strong>millions of customers</strong>.</p>
        <p>My projects focus on helping clients determine the best content management system architecture along with leading the project from design through development to launch.</p>
        <p>Some of the projects that I have worked on are:</p>
        <p><strong>A new site for the City of Boston.</strong> During my time with the City of Boston, I helped complete the redesign of Boston.gov. The new site for the City was built using Drupal and Fleet (a design system I created for the City).</p>
        <p><strong>Updated features for the MTA.</strong> NYC’s transit agency had just received a redesign from a large consultancy. I was tasked with adding new features to their Drupal content management system, and updating their real time transit applications built in Vue.</p>
        <p><strong>A refreshed early childhood hub for the State of Connecticut.</strong> The Office of Early Childhood in Connecticut was in the process of redesigning their site, and I was tasked with migrating their site from a proprietary CMS to Wordpress. This included creating new components for editors and making content entry easier for their editors.</p>
      </div>
      <hr />
      <div className={formStyles.contactOpener}>
        <h2 className={headingStyles.h2}>Get in touch</h2>
        <div className={formStyles.contactDisclaimer}>I would love the opportunity to learn more about your project and see if there is a way for me to help you modernize your organization’s content management. If you are interested in learning more about how I can help on your project, please get in touch below.</div>
      </div>
      <form className={formStyles.contactForm}>
        <div className={formStyles.contactGroup}>
          <label className={formStyles.contactLabel} for="name">Name</label>
          <input className={formStyles.contactField} id="name" type="text" />
        </div>
        <div className={formStyles.contactGroup}>
          <label className={formStyles.contactLabel} for="name">Email</label>
          <input className={formStyles.contactField} id="email" type="email" />
        </div>
        <div className={formStyles.contactGroup}>
          <label className={formStyles.contactLabel} for="name">A brief description of your project</label>
          <textarea className={formStyles.contactField} rows="10" id="message" type="message" />
        </div>
        <div className={formStyles.contactGroup}>
          <div className={formStyles.contactDisclaimer}>To help both of us reduce the potential of a mismatch on your project, I like to be upfront with ballpark costs involved. My rate is <strong>$150 per hour</strong> and projects tend to be in the <strong>$50-$100k range</strong> depending on the project.</div>
        </div>
        <div className={formStyles.contactGroup}>
          <button className={formStyles.contactButton} type="button">Send Message</button>
        </div>
      </form>
      <hr />
      <h2 className={headingStyles.h2}>Some recent thoughts</h2>
      <p>I will occasionally write about content management and development in general. Here are some of those.</p>
      <ul>
        {data.allContentfulArticle.edges.map((edge) => {
          return(
            <li><Link to={`/article/${edge.node.slug}`} key={edge.node.slug}>{ edge.node.title }</Link></li>
          )
        })}
      </ul>
    </Layout>
  )
}

export default Index
